export class Detail {
    cveIdentifiers: Array<string>;
    cweIdentifiers: Array<string>;
    details: string;
    isFixable: boolean;
    overview: string;
    references: string;
    remediation: string;
    snykId: string;
    status: string;
    severity: string;
    title: string;
}
