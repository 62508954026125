import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OverviewComponent } from './pages/overview/overview.component';
import { IssuesComponent } from './pages/issues/issues.component';
import { DetailsComponent } from './pages/details/details.component';
import { LicensesComponent } from './pages/licenses/licenses.component';
import { DependenciesComponent } from './pages/dependencies/dependencies.component';
import { HistoryComponent } from './pages/history/history.component';
import { NotValidPageComponent } from './pages/not-valid-page/not-valid-page.component';

const routes: Routes = [{
        path: ':externalId',
        redirectTo: ':externalId/details',
        pathMatch: 'full'
    }, {
        path: ':externalId/overview',
        component: OverviewComponent
    }, {
        path: ':externalId/issues',
        component: IssuesComponent
    }, {
        path: ':externalId/details',
        component: DetailsComponent
    }, {
        path: ':externalId/licenses',
        component: LicensesComponent
    }, {
        path: ':externalId/dependencies',
        component: DependenciesComponent
    }, {
        path: ':externalId/history',
        component: HistoryComponent
    }, {
        path: '**',
        component: NotValidPageComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,{
        useHash: true
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
