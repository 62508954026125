import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { License } from '../models/license';
import { Globals } from '../globals';

@Injectable({
    providedIn: 'root'
})

export class LicensesService {
    constructor(
        private http: HttpClient,
        private globals: Globals
    ) { }

    getLicenses (externalId: string, licenseFilters: object) {
        return this.http.get<License[]>( [
            this.globals.getApiHostname(externalId),
            this.globals.getApiPath('licenses')
        ].join('/'), {
            params : new HttpParams({
                fromObject: this.globals.getParamsFromFilters(licenseFilters)
            })
        });
    }

    getLicensesCSV (externalId: string) {
        window.open([
            this.globals.getApiHostname(externalId),
            this.globals.getApiPath('licenses')
        ].join('/')+"?format=csv");
    }
}
