import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Detail } from '../models/detail';
import { Globals } from '../globals';

@Injectable({
    providedIn: 'root',
})
export class DetailsService {
    constructor(private http: HttpClient, private globals: Globals) {}

    getIssues(externalId: string, detailFilters: object, detailIds: string = '') {
        const filters = this.globals.getParamsFromFilters(detailFilters);
        if (detailIds) {
            Object.assign(filters, { issueID: detailIds });
        }
        return this.http.get<Detail[]>(
            [this.globals.getApiHostname(externalId), this.globals.getApiPath('issues')].join('/'),
            {
                params: new HttpParams({
                    fromObject: filters,
                }),
            }
        );
    }
}
