import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Detail } from '../../models/detail';
import { DetailsService } from '../../services/details.service';
import { Globals } from '../../globals';

@Component({
    selector: 'app-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
    details: Detail[];
    detailsLoaded = false;
    projectInfo = null;
    projectIntervalHolder = null;

    // for filters
    detailFiltersString = '';
    detailsFilters: any = {
        severity: {
            high: true,
            medium: true,
            low: true,
        },
        status: {
            open: true,
            fixed: true,
        },
    };
    detailIds = '';

    constructor(private route: ActivatedRoute, private detailsService: DetailsService, private globals: Globals) {}

    getIssues(): void {
        this.detailsService
            .getIssues(this.route.snapshot.paramMap.get('externalId'), this.detailsFilters, this.detailIds)
            .subscribe(data => {
                this.details = data;
                this.detailsLoaded = true;
            });
    }

    updateFilters(): void {
        this.detailFiltersString = this.globals.updateFilterLanguage(this.detailsFilters);

        this.getIssues();
    }

    ngOnInit() {
        this.globals.checkForProjectInfo(this.route.snapshot.paramMap.get('externalId'));
        this.projectInfo = this.globals.projectInfo;

        this.projectIntervalHolder = setInterval(() => {
            this.projectInfo = this.globals.projectInfo;
            if (this.globals.getAppLoaded()) {
                clearInterval(this.projectIntervalHolder);
            }
        }, 1000);

        this.route.queryParams.subscribe(({ ids }) => {
            this.detailIds = ids;
            this.getIssues();
        });
    }
}
