import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { OverviewService } from '../../services/overview.service';
import { Globals } from '../../globals';

@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
    overviewLoaded = false;
    overviewStaticLoaded = false;
    chartDrawn = false;
    staticChartDrawn = false;

    monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    overviewChartType = 'LineChart';
    projectInfo = null;
    projectIntervalHolder = null;

    overviewOptionsObject = {
        width: '110%',
        animation: {
            duration: 1000,
            easing: 'in',
        },
        pointSize: 10,
        lineWidth: 3,
        vAxis: {
            maxValue: 0,
            viewWindow: {
                min: 0,
            },
            format: '0',
        },
        legend: 'none',
    };

    overviewStaticOptionsObject = {
        width: '110%',
        animation: {
            duration: 1000,
            easing: 'in',
        },
        pointSize: 10,
        lineWidth: 3,
        vAxis: {
            maxValue: 0,
            viewWindow: {
                min: 0,
            },
            format: '0',
        },
        legend: 'none',
    };

    overviewData = null;
    overviewStaticData = null;

    graphCleaningObject = {
        orderPropertyArray: [],
        orderCheck: {},
    };

    projectGraphCleaningObject = {
        orderPropertyArray: [],
        orderCheck: {},
    };

    securityIssuesChart = {
        title: 'Dependency Issues Over Time',
        data: [
            /*
      [
        'JAN', 1, 1, 2
      ],[
        'FEB', 2, 10, 0
      ],[
        'MAR', 6, 3, 1
      ]
      */
        ],
        /*"historyData":[
      [
        'JAN Year', 1, 1, 2
      ],[
        'FEB Year', 2, 10, 0
      ],[
        'MAR Year', 6, 3, 1
      ]
    ],*/
        columnNames: ['Severity', 'High', 'Medium', 'Low'],
    };

    projectSecurityIssuesChart = {
        title: 'Static Code Issues Over Time',
        data: [
            /*
      [
        'JAN', 1, 1, 2
      ],[
        'FEB', 2, 10, 0
      ],[
        'MAR', 6, 3, 1
      ]
      */
        ],
        /*"historyData":[
      [
        'JAN Year', 1, 1, 2
      ],[
        'FEB Year', 2, 10, 0
      ],[
        'MAR Year', 6, 3, 1
      ]
    ],*/
        columnNames: ['Severity', 'Error', 'Warning', 'Style', 'Portability', 'Performance', 'Information'],
    };

    constructor(
        private route: ActivatedRoute,
        private OverviewService: OverviewService,
        private Globals: Globals,
        private location: Location
    ) {}

    ngOnInit() {
        this.projectInfo = this.Globals.projectInfo;
        this.Globals.checkForProjectInfo(this.route.snapshot.paramMap.get('externalId'));
        this.getOverviewData();
        this.getStaticAnalysisData();

        this.projectIntervalHolder = setInterval(() => {
            this.projectInfo = this.Globals.projectInfo;
            if (this.Globals.getAppLoaded()) {
                clearInterval(this.projectIntervalHolder);
            }
        }, 1000);
    }

    chartReady(): void {
        this.chartDrawn = true;
    }

    staticChartReady(): void {
        this.staticChartDrawn = true;
    }

    getOverviewData(): void {
        this.OverviewService.getOverviewData(this.route.snapshot.paramMap.get('externalId')).subscribe(data => {
            this.overviewData = data;
            // sorting

            if (
                this.overviewData &&
                this.overviewData.securityIssues &&
                this.overviewData.securityIssues.all &&
                this.overviewData.securityIssues.all.length
            ) {
                this.overviewData.securityIssues.all
                    .sort(function(a, b) {
                        if (a.year > b.year) {
                            return 1;
                        } else if (a.year === b.year) {
                            if (a.month > b.month) {
                                return 1;
                            } else {
                                return -1;
                            }
                        } else {
                            return -1;
                        }
                    })
                    .forEach(value => {
                        if (!this.graphCleaningObject[value.year]) {
                            this.graphCleaningObject[value.year] = {};
                        }

                        if (!this.graphCleaningObject[value.year][value.month]) {
                            this.graphCleaningObject[value.year][value.month] = {};
                        }

                        if (!this.graphCleaningObject[value.year][value.month][value.severity]) {
                            this.graphCleaningObject[value.year][value.month][value.severity] = {};
                        }

                        this.graphCleaningObject[value.year][value.month][value.severity] = {
                            total: value.total,
                        };

                        if (!this.graphCleaningObject.orderCheck[value.year + '_' + value.month]) {
                            this.graphCleaningObject.orderPropertyArray.push([value.year, value.month]);
                        }

                        this.graphCleaningObject.orderCheck[value.year + '_' + value.month] = 1;
                    });

                this.graphCleaningObject.orderPropertyArray.forEach(value => {
                    const fullTotal =
                        ((this.graphCleaningObject[value[0]][value[1]].high &&
                            this.graphCleaningObject[value[0]][value[1]].high.total) ||
                            0) +
                        ((this.graphCleaningObject[value[0]][value[1]].medium &&
                            this.graphCleaningObject[value[0]][value[1]].medium.total) ||
                            0) +
                        ((this.graphCleaningObject[value[0]][value[1]].low &&
                            this.graphCleaningObject[value[0]][value[1]].low.total) ||
                            0);

                    /*
                    needs to output like so... 'JAN', 1, 1, 2
                    */
                    this.securityIssuesChart.data.push([
                        this.monthArray[value[1] - 1],
                        (this.graphCleaningObject[value[0]][value[1]].high &&
                            this.graphCleaningObject[value[0]][value[1]].high.total) ||
                            0,
                        (this.graphCleaningObject[value[0]][value[1]].medium &&
                            this.graphCleaningObject[value[0]][value[1]].medium.total) ||
                            0,
                        (this.graphCleaningObject[value[0]][value[1]].low &&
                            this.graphCleaningObject[value[0]][value[1]].low.total) ||
                            0,
                    ]);

                    if (this.overviewOptionsObject.vAxis.maxValue < fullTotal) {
                        this.overviewOptionsObject.vAxis.maxValue = fullTotal;
                    }
                });
            } else {
                this.overviewData = null;
                this.chartReady();
            }

            this.overviewLoaded = true;
        });
    }

    getStaticAnalysisData(): void {
        this.OverviewService.getStaticAnalysisData(this.route.snapshot.paramMap.get('externalId')).subscribe(data => {
            this.overviewStaticData = data;
            // sorting

            if (
                this.overviewStaticData &&
                this.overviewStaticData.securityIssues &&
                this.overviewStaticData.securityIssues.all &&
                this.overviewStaticData.securityIssues.all.length
            ) {
                this.overviewStaticData.securityIssues.all
                    .sort(function(a, b) {
                        if (a.year > b.year) {
                            return 1;
                        } else if (a.year === b.year) {
                            if (a.month > b.month) {
                                return 1;
                            } else {
                                return -1;
                            }
                        } else {
                            return -1;
                        }
                    })
                    .forEach(value => {
                        if (!this.projectGraphCleaningObject[value.year]) {
                            this.projectGraphCleaningObject[value.year] = {};
                        }

                        if (!this.projectGraphCleaningObject[value.year][value.month]) {
                            this.projectGraphCleaningObject[value.year][value.month] = {};
                        }

                        if (!this.projectGraphCleaningObject[value.year][value.month][value.severity]) {
                            this.projectGraphCleaningObject[value.year][value.month][value.severity] = {};
                        }

                        this.projectGraphCleaningObject[value.year][value.month][value.severity] = {
                            total: value.total,
                        };

                        if (!this.projectGraphCleaningObject.orderCheck[value.year + '_' + value.month]) {
                            this.projectGraphCleaningObject.orderPropertyArray.push([value.year, value.month]);
                        }

                        this.projectGraphCleaningObject.orderCheck[value.year + '_' + value.month] = 1;
                    });

                this.projectGraphCleaningObject.orderPropertyArray.forEach(value => {
                    const fullTotal =
                        ((this.projectGraphCleaningObject[value[0]][value[1]].error &&
                            this.projectGraphCleaningObject[value[0]][value[1]].error.total) ||
                            0) +
                        ((this.projectGraphCleaningObject[value[0]][value[1]].information &&
                            this.projectGraphCleaningObject[value[0]][value[1]].information.total) ||
                            0) +
                        ((this.projectGraphCleaningObject[value[0]][value[1]].performance &&
                            this.projectGraphCleaningObject[value[0]][value[1]].performance.total) ||
                            0) +
                        ((this.projectGraphCleaningObject[value[0]][value[1]].portability &&
                            this.projectGraphCleaningObject[value[0]][value[1]].portability.total) ||
                            0) +
                        ((this.projectGraphCleaningObject[value[0]][value[1]].style &&
                            this.projectGraphCleaningObject[value[0]][value[1]].style.total) ||
                            0) +
                        ((this.projectGraphCleaningObject[value[0]][value[1]].warning &&
                            this.projectGraphCleaningObject[value[0]][value[1]].warning.total) ||
                            0);

                    /*
                                        needs to output like so... 'JAN', 1, 1, 2
                                        */
                    this.projectSecurityIssuesChart.data.push([
                        this.monthArray[value[1] - 1],
                        (this.projectGraphCleaningObject[value[0]][value[1]].error &&
                            this.projectGraphCleaningObject[value[0]][value[1]].error.total) ||
                            0,
                        (this.projectGraphCleaningObject[value[0]][value[1]].information &&
                            this.projectGraphCleaningObject[value[0]][value[1]].information.total) ||
                            0,
                        (this.projectGraphCleaningObject[value[0]][value[1]].performance &&
                            this.projectGraphCleaningObject[value[0]][value[1]].performance.total) ||
                            0,
                        (this.projectGraphCleaningObject[value[0]][value[1]].portability &&
                            this.projectGraphCleaningObject[value[0]][value[1]].portability.total) ||
                            0,
                        (this.projectGraphCleaningObject[value[0]][value[1]].style &&
                            this.projectGraphCleaningObject[value[0]][value[1]].style.total) ||
                            0,
                        (this.projectGraphCleaningObject[value[0]][value[1]].warning &&
                            this.projectGraphCleaningObject[value[0]][value[1]].warning.total) ||
                            0,
                    ]);

                    if (this.overviewStaticOptionsObject.vAxis.maxValue < fullTotal) {
                        this.overviewStaticOptionsObject.vAxis.maxValue = fullTotal;
                    }
                });
            } else {
                this.overviewStaticData = null;
                this.staticChartReady();
            }

            this.overviewStaticLoaded = true;
        });
    }
}
