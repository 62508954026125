import { License } from './license';

export class Dependency {
    dependencyId: string;
    repositoryId: string;
    name: string;
    version: string;
    parentDependency: string;
    issueHighCount: number;
    issueHighIds: string[];
    issueMediumCount: number;
    issueMediumIds: string[];
    issueLowCount: number;
    issueLowIds: string[];
    licenses: License[];
    fundspringId: string;
    packageManager: string;
    url: string;
    depth: number;
    highlight: boolean;
    dependencies: Dependency[];

    constructor(json: any) {
        this.name = json.name;
        this.version = json.version;
        this.issueHighCount = json.issueHighCount;
        this.issueMediumCount = json.issueMediumCount;
        this.issueLowCount = json.issueLowCount;
        this.issueHighIds = json.issueHighIds;
        this.issueMediumIds = json.issueMediumIds;
        this.issueLowIds = json.issueLowIds;
        this.fundspringId = json.fundspringId;
        this.packageManager = json.packageManager;
        this.depth = json.depth;
        this.dependencies = Dependency.buildTree(json.dependencies, json.depth + 1);
        this.licenses = this.getLicenses(json.licenses);
        this.url = this.snykDetailUrl();
        this.highlight = this.getHighlight();
    }

    static buildTree(dependencies: any[], depth: number): Dependency[] {
        if (!dependencies) {
            return null;
        }

        return dependencies.map(json => {
            Object.assign(json, { depth: depth });
            const dep = new Dependency(json);
            return dep;
        });
    }

    private getLicenses(licenses: string[]): License[] {
        if (!licenses) {
            return [new License({ name: 'Unknown' })];
        }

        return licenses.map((name: string) => new License({ name }));
    }

    private snykDetailUrl() {
        if (this.packageManager && this.name) {
            return `https://app.snyk.io/vuln/${this.packageManager}:${this.name}`;
        }
        return null;
    }

    private getHighlight(): boolean {
        if (this.depth === 1) {
            return this.calcHighlight();
        }
        return false;
    }

    private calcHighlight(): boolean {
        if (this.issueLowCount > 0 || this.issueMediumCount > 0 || this.issueHighCount > 0) {
            return true;
        }

        if (this.dependencies) {
            for (const dep of this.dependencies) {
                if (dep.calcHighlight()) {
                    return true;
                }
            }
        }

        return false;
    }
}
