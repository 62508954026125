import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from './globals';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
    // pull from URL...
    projectInfo = null;
    externalId = "notSet";
    vdAppLoaded = false;
    isClosed = true;
    navIntervalHolder = null;

    openMenu(){
        this.isClosed = !this.isClosed;
    }

    justToOverviewTab(){
        var securityIssueTab;
        if(
            this.router &&
            this.router.url &&
            this.router.url.search('overview') > -1 &&
            ( securityIssueTab = document.getElementById('securityIssuesTab') )
        ){
            securityIssueTab.click();
        }
    }

    ngOnInit(){
        var self = this;
        this.vdAppLoaded = true;
        this.navIntervalHolder = setInterval(()=>{
            this.externalId = this.Globals.getExternalId();
            if(
                this.Globals.getAppLoaded()
            ){
                clearInterval(this.navIntervalHolder);
            }
        }, 1000);
        
        this.Globals.startScrollingListener();
    }

    constructor(
        private Globals: Globals,
        private router: Router
    ) {
        this.projectInfo = Globals.projectInfo;
        this.vdAppLoaded = Globals.vdAppLoaded;
    }
}
