import { Component, OnInit, Input } from '@angular/core';
import { Detail } from '../../../models/detail';

@Component({
    selector: 'app-issue-detail',
    templateUrl: './issue-detail.component.html',
    styleUrls: ['./issue-detail.component.scss'],
})
export class IssueDetailComponent implements OnInit {
    @Input() detail: Detail;

    constructor() {}

    ngOnInit() {}
}
