import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Globals } from '../../globals';

import { Dependency } from '../../models/dependency';
import { DependenciesService } from '../../services/dependencies.service';

@Component({
    selector: 'app-dependencies',
    templateUrl: './dependencies.component.html',
    styleUrls: ['./dependencies.component.scss'],
})
export class DependenciesComponent implements OnInit {
    dependencyGraph = null;
    dependencyGraphLoaded = false;
    projectInfo = null;
    projectIntervalHolder = null;
    activeTab = true;

    isLoadingDependencyGraph = false;

    constructor(
        private route: ActivatedRoute,
        private dependenciesService: DependenciesService,
        private globals: Globals
    ) {}

    getDependencyGraph() {
        if (this.isLoadingDependencyGraph) {
            return;
        }

        this.isLoadingDependencyGraph = true;

        this.dependenciesService.getDependencyGraph(this.route.snapshot.paramMap.get('externalId')).subscribe(data => {
            if (data) {
                this.dependencyGraph = Dependency.buildTree([data], 0);
            }
            this.isLoadingDependencyGraph = false;
            this.dependencyGraphLoaded = true;
        });
    }

    getFundspringUrl(fundspringIdString: string) {
        return this.globals.getFundspringProjectUrl(fundspringIdString);
    }

    ngOnInit() {
        this.globals.checkForProjectInfo(this.route.snapshot.paramMap.get('externalId'));
        this.projectInfo = this.globals.projectInfo;

        this.getDependencyGraph();

        this.projectIntervalHolder = setInterval(() => {
            this.projectInfo = this.globals.projectInfo;
            if (this.dependencyGraph) {
                this.dependencyGraph[0].name = this.projectInfo.name;
            }
            if (this.globals.getAppLoaded()) {
                clearInterval(this.projectIntervalHolder);
            }
        }, 1000);
    }
}
