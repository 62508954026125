import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GoogleChartsModule } from 'angular-google-charts';
import { MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { FormsModule } from '@angular/forms';
import { PrismModule } from '@ngx-prism/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OverviewComponent } from './pages/overview/overview.component';
import { HistoryComponent } from './pages/history/history.component';
import { IssuesComponent } from './pages/issues/issues.component';
import { DetailsComponent } from './pages/details/details.component';
import { IssueDetailComponent } from './pages/issues/issue-detail/issue-detail.component';
import { DependenciesComponent } from './pages/dependencies/dependencies.component';
import { LicensesComponent } from './pages/licenses/licenses.component';
import { NotValidPageComponent } from './pages/not-valid-page/not-valid-page.component';
import { Globals } from './globals';
import { DepGraphNodeComponent } from './pages/dependencies/dep-graph-node/dep-graph-node.component';
import { FullscreenLoaderComponent } from './shared/loader/fullscreen-loader/fullscreen-loader.component';
import { FooterComponent } from './shared/footer/footer.component';
import { DetailComponent } from './pages/detail/detail.component';
import { ProjectInfoComponent } from './shared/project-info/project-info.component';

export function markedOptionsFactory(): MarkedOptions {
    const renderer = new MarkedRenderer();
    const linkRenderer = renderer.link;

    renderer.link = (href, title, text) => {
        const html = linkRenderer.call(renderer, href, title, text);
        return html.replace(/^<a /, '<a target="_blank" ');
    };

    return {
        renderer: renderer,
    };
}

@NgModule({
    declarations: [
        AppComponent,
        OverviewComponent,
        HistoryComponent,
        IssuesComponent,
        DetailsComponent,
        IssueDetailComponent,
        DependenciesComponent,
        LicensesComponent,
        NotValidPageComponent,
        DepGraphNodeComponent,
        FullscreenLoaderComponent,
        FooterComponent,
        DetailComponent,
        ProjectInfoComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        GoogleChartsModule,
        MarkdownModule.forRoot({
            markedOptions: {
                provide: MarkedOptions,
                useFactory: markedOptionsFactory,
            },
        }),
        HttpClientModule,
        FormsModule,
        PrismModule,
    ],
    providers: [Globals],
    bootstrap: [AppComponent],
})
export class AppModule {}
