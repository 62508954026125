import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-not-valid-page',
    templateUrl: './not-valid-page.component.html',
    styleUrls: ['./not-valid-page.component.scss'],
})
export class NotValidPageComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
