import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Overview } from '../models/overview';
import { Globals } from '../globals';

@Injectable({
    providedIn: 'root'
})

export class OverviewService {
    constructor(
        private http: HttpClient,
        private globals: Globals
    ) { }

    getOverviewData (externalId: string) {
        return this.http.get<Overview[]>([
            this.globals.getApiHostname(externalId),
            this.globals.getApiPath('summary')
        ].join('/') );
    }

    getStaticAnalysisData (externalId: string) {
        return this.http.get<Overview[]>([
            this.globals.getApiHostname(externalId),
            this.globals.getApiPath('staticanalysis/summary')
        ].join('/') );
    }

    getTaskData (externalId: string) {
        return this.http.get<Overview[]>([
            this.globals.getApiHostname(externalId),
            this.globals.getApiPath('task')
        ].join('/') );
    }
}
