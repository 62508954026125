import { Component, OnInit, Input } from '@angular/core';
import { Detail } from '../../models/detail';

@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit {
    @Input() detail: Detail;

    constructor() {}

    ngOnInit() {}
}
