import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ViewEncapsulation } from '@angular/core';

import { Issue, ProjectIssue } from '../../models/issues';
import { IssuesService } from '../../services/issues.service';
import { Globals } from '../../globals';

@Component({
    selector: 'app-issues',
    templateUrl: './issues.component.html',
    styleUrls: ['./issues.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class IssuesComponent implements OnInit {
    issues: Issue[];
    issuesCollection = null;
    isLoadingIssues = false;
    isIssuesEnded = false;

    projectIssues: ProjectIssue[];
    projectIssuesCollection = null;
    isLoadingProjectIssues = false;
    isProjectIssuesEnded = false;

    issuesLoaded = false;
    projectIssuesLoaded = false;

    issuesNameMap = {};
    issuesNameArray = [];
    projectInfo = null;
    projectIntervalHolder = null;

    // for filters
    issueFiltersString = '';
    issueFiltersArray = [];
    issueFilters = {
        severity: {
            high: true,
            medium: true,
            low: true,
        },
        status: {
            open: true,
            fixed: true,
        },
        isFixable: {
            true: true,
            false: true,
        },
        limit: 25,
        offset: 0,
        isLoading: false,
    };

    projectIssueFiltersString = '';
    projectIssueFilters = {
        severity: {
            error: true,
            warning: true,
            style: true,
            portability: true,
            performance: true,
            information: true,
        },
        status: {
            open: true,
            fixed: true,
        },
        limit: 25,
        offset: 0,
        isLoading: false,
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private issuesService: IssuesService,
        private globals: Globals,
        private location: Location
    ) {}

    ngOnInit() {
        this.projectInfo = this.globals.projectInfo;
        this.globals.checkForProjectInfo(this.route.snapshot.paramMap.get('externalId'));
        this.getIssues(false);
        this.getProjectIssues(false);

        this.projectIntervalHolder = setInterval(() => {
            this.projectInfo = this.globals.projectInfo;
            if (this.globals.getAppLoaded()) {
                clearInterval(this.projectIntervalHolder);
            }
        }, 1000);
    }

    createCVELinks(cveArray) {
        // MOVE TO UI LAYER
        // https://cve.mitre.org/cgi-bin/cvename.cgi?name=CVE-2017-0663
        let stringBuffer = '';
        for (let i = 0; i < cveArray.length; i++) {
            stringBuffer +=
                '<a class="cwe-cve-link" target="_blank" href="https://cve.mitre.org/cgi-bin/cvename.cgi?name=' +
                cveArray[i] +
                '">' +
                cveArray[i] +
                '</a>';
        }

        return stringBuffer;
    }

    createCWELinks(cweArray) {
        // MOVE TO UI LAYER
        // https://cwe.mitre.org/data/definitions/611.html
        // I cant stand typescript sometimes...
        let justCWENumber = null,
            stringBuffer = '';

        for (let i = 0; i < cweArray.length; i++) {
            justCWENumber = String(cweArray[i]).split('-')[1];

            stringBuffer +=
                '<a class="cwe-cve-link" target="_blank" href="https://cwe.mitre.org/data/definitions/' +
                justCWENumber +
                '.html">' +
                cweArray[i] +
                '</a>';
        }

        return stringBuffer;
    }

    concatCVEandCWEArray(cveArray, cweArray) {
        // move to UI
        const concatingArray = [];
        if (cveArray) {
            concatingArray.push(cveArray);
        }
        if (cweArray) {
            concatingArray.push(cweArray);
        }
        return concatingArray.join(', ');
    }

    getIssues(resetCollectionBoolean: boolean) {
        if (this.isLoadingIssues) {
            return false;
        }

        const self = this;
        this.isLoadingIssues = true;

        this.issuesService
            .getIssues(this.route.snapshot.paramMap.get('externalId'), this.issueFilters)
            .subscribe(data => {
                const self = this;
                this.issues = data;

                if (this.issues) {
                    if (this.issuesCollection === null || resetCollectionBoolean) {
                        this.issuesCollection = [];
                    }

                    if (this.issues.length) {
                        this.issues.forEach(function(issueItem) {
                            if (!issueItem) {
                                return false;
                            }

                            if (issueItem.cveIdentifiers && issueItem.cveIdentifiers.length) {
                                issueItem.cveIdentifiers = self.createCVELinks(issueItem.cveIdentifiers);
                            } else {
                                issueItem.cveIdentifiers = '';
                            }

                            if (issueItem.cweIdentifiers && issueItem.cweIdentifiers.length) {
                                issueItem.cweIdentifiers = self.createCWELinks(issueItem.cweIdentifiers);
                            } else {
                                issueItem.cweIdentifiers = '';
                            }
                        });

                        this.issuesCollection = this.issuesCollection.concat(this.issues);

                        this.globals.updatePaginationCallback('issues', function() {
                            if (!self.isLoadingIssues) {
                                self.issueFilters.offset += self.issueFilters.limit;
                                self.getIssues(false);
                            }
                        });
                    } else {
                        this.isIssuesEnded = true;
                        this.globals.updatePaginationCallback('issues', function() {});
                    }
                }

                this.issuesLoaded = true;
                this.isLoadingIssues = false;
                /*
                    I want to come up with filters from the data itself
                    if(!this.issuesNameArray.length){
                    assuming length means this has already been set
                    so will only have to do this once...
                    for(var i=0;i<this.issues.length;i++){
                        if(!this.issuesNameMap[this.issues[i].severity]){
                        this.issuesNameMap[this.issues[i].severity] = 1;
                        this.issuesNameArray.push(this.issues[i].severity);
                        }
                    }
                    }
                */
            });
    }

    goToIssueDetail(issue: Issue) {
        this.router.navigate([this.route.snapshot.paramMap.get('externalId'), 'details'], {
            queryParams: { ids: issue.snykId },
        });
    }

    getProjectIssues(resetCollectionBoolean: boolean) {
        if (this.isLoadingProjectIssues) {
            return false;
        }

        const self = this;
        this.isLoadingProjectIssues = true;

        this.issuesService
            .getProjectIssues(this.route.snapshot.paramMap.get('externalId'), this.projectIssueFilters)
            .subscribe(data => {
                this.projectIssues = data;

                if (this.projectIssues) {
                    if (this.projectIssuesCollection === null || resetCollectionBoolean) {
                        this.projectIssuesCollection = [];
                    }

                    if (this.projectIssues.length) {
                        this.projectIssuesCollection = this.projectIssuesCollection.concat(this.projectIssues);

                        this.globals.updatePaginationCallback('projectIssues', function() {
                            if (!self.isLoadingProjectIssues) {
                                self.projectIssueFilters.offset += self.projectIssueFilters.limit;
                                self.getProjectIssues(false);
                            }
                        });
                    } else {
                        this.isProjectIssuesEnded = true;
                        this.globals.updatePaginationCallback('projectIssues', function() {});
                    }
                }

                this.projectIssuesLoaded = true;
                this.isLoadingProjectIssues = false;

                /*
                I want to come up with filters from the data itself
                if(!this.issuesNameArray.length){
                        assuming length means this has already been set
                        so will only have to do this once...
                    for(var i=0;i<this.issues.length;i++){
                        if(!this.issuesNameMap[this.issues[i].severity]){
                            this.issuesNameMap[this.issues[i].severity] = 1;
                            this.issuesNameArray.push(this.issues[i].severity);
                        }
                    }
                }
                */
            });
    }

    /*
        Unite the two below functions
    */
    getIssuesCSV(): void {
        this.issuesService.getIssuesCSV(this.route.snapshot.paramMap.get('externalId'), this.issueFilters);
    }

    getProjectIssuesCSV(): void {
        this.issuesService.getProjectIssuesCSV(
            this.route.snapshot.paramMap.get('externalId'),
            this.projectIssueFilters
        );
    }

    /*
        Unite the two below functions as well
    */
    updateFilters(): void {
        this.issueFiltersString = this.globals.updateFilterLanguage(this.issueFilters);
        this.issueFilters.offset = 0;
        this.getIssues(true);
    }

    updateProjectIssueFilters(): void {
        this.projectIssueFiltersString = this.globals.updateFilterLanguage(this.projectIssueFilters);
        this.projectIssueFilters.offset = 0;
        this.getProjectIssues(true);
    }

    sortIssues(propertyToSortBy): void {
        console.log(propertyToSortBy);
    }
}
