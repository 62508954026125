export class License {
    name: string;
    dependencyCount: number;
    url: string;

    constructor(json) {
        this.name = json.name.trim();
        this.dependencyCount = json.dependencyCount;
        this.url = this.spdxUrl();
    }

    private spdxUrl() {
        const name = this.name;
        if (name === 'Unknown' || name === 'Unlicense') {
            return null;
        }
        return `https://spdx.org/licenses/${name}.html`;
    }
}
