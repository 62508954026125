import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Project } from '../models/project';

@Injectable({
    providedIn: 'root'
})

export class ProjectService {
    constructor(
        private http: HttpClient
    ) {}

    getProjectInfo (
        urlBase: string,
        externalId: string
    ) {
        return this.http.get<Project[]>([
            urlBase,
            externalId
        ].join("/"));
    }
}
